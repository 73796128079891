import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { UserLocationState } from './user-location.state';
import { GuestUserProvider } from '../providers/guest-user.provider';
import { STORAGE_KEY_USER } from './constants';

import { HttpErrorResponse } from '@angular/common/http';
import { MoyaUserProvider } from '../providers/moya-user.provider';

export enum UserType {
  USER = 'user',
  GUEST = 'guest',
  MOYA = 'moya',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  GOOGLE = 'google',
}
export interface User {
  jwt: string;
  id: string;
  userType: UserType;
  guestCredentials?: {
    id: string;
    jwt: string;
  };
  isEmailValidated?: boolean; // Users only (not guests)
  isLegacy?: boolean; // Can be removed once the migration is done
}

@Injectable({ providedIn: 'root' })
export class UserState {
  constructor(
    private preferenceService: PreferencesService,
    private userLocationState: UserLocationState,
    private guestUserProvider: GuestUserProvider,
    private moyaUserProvider: MoyaUserProvider,
  ) {}

  private readonly STORAGE_KEY = STORAGE_KEY_USER;

  user$!: BehaviorSubject<User>;
  isGuest$!: Observable<boolean>;
  isMoya$!: Observable<boolean>;

  async initialize(moyaId?: string): Promise<void> {
    // Optional login as Moya
    if (moyaId) {
      const moyaUser = await firstValueFrom(
        this.moyaUserProvider.loginWithMoya(moyaId, this.userLocationState.userLocation$.value.countryCode),
      ).catch((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return this.initialize();
        } else {
          throw error;
        }
      });

      if (moyaUser) {
        await this.set(moyaUser);
        return Promise.resolve();
      }
    }

    const storedUser = await this.get();

    // If no user is stored, we set the user as a guest.
    if (!storedUser) {
      // Login as guest
      const guestUser = await firstValueFrom(this.guestUserProvider.getGuestUser(this.userLocationState.userLocation$.value.query));
      await this.set(guestUser);
    } else {
      this.initObservables(storedUser);
    }

    return Promise.resolve();
  }

  private async get(): Promise<User | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<User>): Promise<void> {
    const updatedOptions: User = {
      ...this.user$?.value,
      ...settings,
    };

    if (this.user$) {
      this.user$.next(updatedOptions);
    } else {
      this.initObservables(updatedOptions);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }

  async remove(): Promise<void> {
    await this.preferenceService.remove(this.STORAGE_KEY);
  }

  private initObservables(user: User): void {
    this.user$ = new BehaviorSubject<User>(user);
    this.isGuest$ = this.user$.pipe(map(user => user.userType === UserType.GUEST));
    this.isMoya$ = this.user$.pipe(map(user => user.userType === UserType.MOYA));
  }
}
