import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { STORAGE_KEY_DEFAULT_PROJECT } from './constants';
import { ProjectProvider } from '../providers/project.provider';
import { Project, Projects, StoredProject } from '../models/projects.model';
import { Feature, LoggerService } from '../services/logger.service';

@Injectable({ providedIn: 'root' })
export class DefaultProjectState {
  constructor(
    private preferenceService: PreferencesService,
    private projectProvider: ProjectProvider,
    private loggerService: LoggerService,
  ) {}

  private readonly STORAGE_KEY = STORAGE_KEY_DEFAULT_PROJECT;
  defaultProject$!: BehaviorSubject<StoredProject>;

  async initialize(): Promise<void> {
    let storedDefaultProject = await this.get();

    if (!storedDefaultProject) {
      const projects: Projects = await firstValueFrom(this.projectProvider.getProjects(1));

      if (!projects.projects.length) {
        this.loggerService.fatal('DefaultProjectState::initialize', {
          feature: Feature.APP_INIT,
          context: 'No default project retreived from the API',
        });

        throw Error('No default project retreived from the API');
      }

      const project = projects.projects[0];

      await this.set(project);
    } else {
      // Since https://worldreader.atlassian.net/browse/B20CP-493, we need the isDefault key. Pulling the API again if it's not the case
      if (!('isDefault' in storedDefaultProject)) {
        const project = await firstValueFrom(this.projectProvider.getProject((storedDefaultProject as StoredProject).code));
        storedDefaultProject = this.getStoredProject(project);
        await this.set(project);
      }

      this.defaultProject$ = new BehaviorSubject<StoredProject>(storedDefaultProject);
    }

    return Promise.resolve();
  }

  private async get(): Promise<StoredProject | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(project: Project): Promise<void> {
    // We store only part of the project in the local storage due to memory limitations
    const storedProject: StoredProject = this.getStoredProject(project);

    if (this.defaultProject$) {
      this.defaultProject$.next(storedProject);
    } else {
      this.defaultProject$ = new BehaviorSubject<StoredProject>(storedProject);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(storedProject));
  }

  async reset(): Promise<void> {
    await this.preferenceService.remove(this.STORAGE_KEY);
    await this.initialize();
  }

  private getStoredProject(project: Project): StoredProject {
    return {
      id: project.id,
      name: project.name,
      code: project.code,
      grades: project.grades,
      organizationCode: project.organizationCode,
      programId: project.programId,
      isDefault: project.isDefault || false,
      brandBannerUrl: project?.brandBannerUrl,
    };
  }
}
