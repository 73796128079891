export const environment = {
  production: true,
  envName: 'prod',
  host: 'https://booksmart.worldreader.org',
  apiBaseUrl: 'https://books-api.worldreader.org/v1',
  settingsBaseUrl: 'https://settings-api.worldreader.org/v1',
  usersBaseUrl: 'https://user-api.worldreader.org/v1',
  projectBaseUrl: 'https://org-api.worldreader.org/v1/client/projects',
  booksBaseUrl: 'https://books-api.worldreader.org/v1',
  contentBaseUrl: 'https://content-api.worldreader.org/v1',
  locationApi: 'https://pro.ip-api.com/json/?key=QvKWwQKnad2wWP7&fields=countryCode,regionName,city,district,zip,query',
  worldreaderBaseUrl: 'https://org-api.worldreader.org/v1',

  // Web
  webClientCode: 'org.worldreader.booksmart.pwa.web',
  webClientToken: 'gHX6mTKc4oL7k83OSUAI2dSh',

  // Desktop
  deskTopClientCode: 'org.worldreader.booksmart.pwa.desktop',
  deskTopClientToken: 'wyVr2TnlTeQj8cAoUG557R0M',

  // Android
  androidClientCode: 'org.worldreader.booksmart.pwa.android',
  androidClientToken: 'w8JUrN5S1EZc84C3sZzeAZZP',

  // IOS
  iosClientCode: 'org.worldreader.booksmart.pwa.ios',
  iosClientToken: '9ZeVZyYqHqN145z8dui6ahPV',

  // Data - Analytics
  userAgentsBlacklist: ['ELB-HealthChecker/2.0'],

  // Logs
  sentryDsn: 'https://e642c40901bdef2d64c2bc24f4005698@o4507367053524992.ingest.de.sentry.io/4507367093895248',
};
